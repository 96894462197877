
import { Vue, Component } from 'vue-property-decorator';
import { IUser } from './models/user.models';

@Component
export default class Auth extends Vue {
    menu: any[] = [
        {
            header: true,
            hiddenOnCollapse: true,
        },
        {
            href: '/trade/promo',
            title: 'Promo leaflets',
            icon: 'fa fa-users',
            exactPath: false,
            alias: '/trade/promo/:id?',
        },
        {
            title: 'Account',
            icon: 'fa fa-user',
            child: [
                {
                    title: 'Logout',
                    icon: 'fa fa-power-off',
                    meta: 'log-out',
                },
            ],
        },
    ];
    collapsed = true;
    isOnMobile = screen.width < 768;

    onToggleCollapse(collapsed: boolean) {
        this.collapsed = collapsed;
    }
    async onItemClick(event: any, item: any, node: any) {
        if (item.meta === 'log-out') {
            await this.$store.dispatch('logout');
            this.$router.push({ name: 'login' });
        }
    }

    get getUser(): IUser {
        return this.$store.state.user;
    }
}
